import { App, Footer, Header, Main } from 'components/app/app.style';
import { Link, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
import Loader from 'components/loader';
import { ThemeProvider } from 'styled-components';

const Index = lazy(() => import('routes/index'));
const Ticker = lazy(() => import('routes/ticker'));

const theme = {};

export default class Root extends React.Component {
  render() {
    return (
      <Router>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<Loader/>}>
            <App>
              <Header>
                <Link to='/'>stocklist</Link>
              </Header>
              <Main>
                <Switch>
                  <Route exact path='/' component={Index}/>
                  <Route path='/ticker' component={Ticker}/>
                </Switch>
              </Main>
              <Footer/>
            </App>
          </Suspense>
        </ThemeProvider>
      </Router>
    );
  }
}
