import { Container, Loader } from 'components/loader/loader.style';
import React from 'react';

export default function LoaderComponent() {
  return (
    <Container>
      <Loader viewBox="25 25 50 50">
        <circle cx="50" cy="50" r="20"></circle>
      </Loader>
    </Container>
  );
}
