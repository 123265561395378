import media from 'assets/styles/breakpoints.js';
import styled from 'styled-components';

export const App = styled.div`
  ${media.tablet`
    justify-content: center;
  `}

  background: var(--color-bg);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  text-align: center;

  &:before {
    background: linear-gradient(45deg, transparent, var(--color-secondary));
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0.1;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }
`;

export const Footer = styled.footer`
  align-items: center;
  color: white;
  display: flex;
  height: 60px;
  justify-content: flex-start;
  width: 100%;
`;

export const Header = styled.header`
  ${media.tablet`padding: 0 20px;`}

  align-items: center;
  color: white;
  display: flex;
  height: 60px;
  justify-content: flex-start;
  padding: 0 120px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  a {
    font-weight: 600;
    font-size: calc(10px + 2vmin);
  }
`;

export const Main = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding-top: 60px;
  position: relative;
`;
