import 'assets/styles/base.css';
import 'assets/styles/vars.css';
import App from 'components/app';
import React from 'react';
import ReactDOM from 'react-dom';
import { unregister } from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers at https://bit.ly/CRA-PWA
unregister();
